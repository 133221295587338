import React from "react";
import styles from "./Manifesto.module.css";
import logo from "../../assets/logo.png";
import { Link } from "react-router-dom";
import { Footer } from "../../components/Footer/Footer";
import { AnimatedBackground } from "../../components/AnimatedBackground/AnimatedBackground";

export const Manifesto = () => {
  return (
    <AnimatedBackground>
      <div className={styles.navbar}>
        <Link to="/">
          <img src={logo} className={styles.logo} alt="We Sifted Logo" />
        </Link>
        <div className={styles.navButtons}>
          <Link to="/manifesto">
            <button className={styles.navButton}>manifesto</button>
          </Link>
          <a href="mailto:me@yashdagade.com" target="_blank" rel="noreferrer">
            <button className={styles.signUpButton}>make it yours!</button>
          </a>
        </div>
      </div>

      <div className={styles.manifesto}>
        <h1 className={styles.manifestoTitle}>Bills don't have to be Complicated</h1>
        <p className={styles.manifestoMeta}>January 1, 2025</p>
        <hr className={styles.divider} />
        <div className={styles.manifestoText}>
          <p>We are flooded with noise. With fog. With jargon. Rules are written behind closed doors. Laws are changing overnight. We are drowning.</p>
          <p>We refuse. So, we sifted it. Because the laws that shape us shouldn't be buried in complexity. Because complexity is a tool of confusion. Because clarity is power.</p>
          <p>Never settle. Because you need to be ready — not just for today, but for tomorrow. Because knowledge is power — only if it's yours. So, we sift — for you. No fluff. No filler. Just the information that impacts your world. This is about control. Control of your choices. Control of your future. The future belongs to those who see clearly.</p>
        </div>
      </div>
      <Footer />
    </AnimatedBackground>
  );
};